.zoom-content{
    cursor: pointer;
    overflow: hidden;
    outline:none;
}

canvas {
    outline: none !important;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
  }

  button:focus {outline:0;}

  a:focus {
    outline: none;
    }
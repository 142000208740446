
.swatch-filters ul {
  display: block;
  text-transform: uppercase;
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2;
  }

 .swatch-filters li {
      text-align: left;
      list-style: none;
      line-height: 24px;
      font-size:14px;
      cursor: pointer;
  }

  .swatch-filters.active{
    color:#000;
  }

  .swatch-filter.hidden{
    display:none;
  }

  .swatch-filter.active::before{
    font-family: FontAwesome;
    content: '\f2d3';
    padding-right:4px;
  }

  .swatch-filter.none-active{
    cursor: default;
  }

  .swatch-filter.none-active:hover{
    color:#000;
    cursor: pointer; 
  }

  .swatch-filter.active{
    color:#000;
  }

  .swatch-filter.in-active{
    opacity:.3;
    cursor: default;
  }

  .filter-container{
    padding:10px;
  }

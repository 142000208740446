/* Override bootstrap & precompiled CSS */
.pagination-container{
width:100%;
}

.pagination{
    margin:0;
    padding:0;
    justify-content:center;
    align-content: center;
    font-size:14px;
}

.pagination > li > a, .pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: #222222;
	text-decoration: none;
	border: none;
	cursor:pointer;
}

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
	z-index: 2;
	color: #000000;
	font-weight: bold;
	background-color: transparent;
	border:none;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
	z-index: 3;
	color: #000000;
	font-weight: bold;
	cursor: default;
	background-color: transparent;
	border:none;
}

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
	color: #777;
	cursor: not-allowed;
	border:none;
}

.swatch-filters{
	width:100%;
}

.swatches-container{
	padding:10px;
	width:100%;
}

/* INLINE FILTERS */ 

/* 
.swatch-filters ul{
	columns:1;
	padding:0;
}

.swatch-filters li{
	white-space: nowrap;
	text-transform: capitalize;
	border-bottom: 1px solid #eaeaea;
	line-height: 36px;
	font-size: 16px;
	font-weight: 200;
}

.swatches-container .react-sanfona{
	display:flex;
}

.swatches-container .react-sanfona .react-sanfona-item{
	width:33%;
	border: none;
	position: relative;
}

.swatches-container .react-sanfona-item:not(:first-of-type):not(:last-of-type){
	margin-left:5px;
	margin-right:5px;
}

.swatches-container .react-sanfona-item:first-of-type{
	margin-right:10px;
}

.swatches-container .react-sanfona-item:last-of-type{
	margin-left: 10px;
}

.swatches-container .react-sanfona .react-sanfona-item-title:after{
	border-left:1px solid #eaeaea;
}

.swatches-container .react-sanfona h3{
	padding:0;
	padding-left:20px;
	text-align: center;
	vertical-align: middle;
	line-height: 48px;
}

.swatches-container .react-sanfona-item-expanded.filter-expanded .filters{
	border-top:1px solid #eaeaea;
}

.swatches-container .react-sanfona .filters{
	width:fit-content;
	position: absolute;
	min-width:100%;
	z-index: 100000;
}

.swatches-container .react-sanfona .filters.last{
	right:0;
	float:right;
}

.swatches-container .react-sanfona .filters.first{
	left:0;
	float:left;
}

.swatches-container .react-sanfona-item-body-wrapper{
	overflow-y: scroll;
	max-height: 400px;
}

 */
/* END OF INLINE FILTERS */

.swatch-caption-1{

}

.swatch-caption-2{

}

.picker-container {
	width:100%;
}

.swatch-container{
	margin-top:10px;
	display:inline-flex;
	flex-wrap:wrap;
	width:100%;
}

.swatch{
	background-color:transparent;
	padding:2px;
}

.swatch.active img, .swatch.active canvas{
	border: 1px solid #000;
	padding:2px;
}

.swatch.active .swatch-label{
	color:#000;
}

.swatch img{
    width:100%;
}

.swatch canvas{
	width:100%;
	margin-bottom: -6px; /* CSS FIX */
}

.swatch-label{
    font-size:12px;
	line-height:16px;
	overflow:hidden;
	text-overflow: ellipsis;
	
}

.swatch-border{
	position: relative;
	width: 25%;
	background-color:transparent;
	font-size:1.0rem;
	cursor: pointer;
}

.swatch-close{
	margin-top:-10px;
	font-size:24px;
	text-align: center;
	vertical-align: middle;
	background-color: #fff;
	float:right;
	cursor:pointer;
}

.swatch-modal-content{
	background-color: #fff;
}

.swatch-modal-image{
}

.swatch-modal{
	opacity:0;
	position:absolute;
	padding:10px;
	font-size:14px;
	width:200px;
	height:auto;	
	background-color: #fff;
	z-index: 1000;
	-webkit-box-shadow: 10px 10px 13px -3px rgba(0,0,0,0.5);
	-moz-box-shadow: 10px 10px 13px -3px rgba(0,0,0,0.5);
	box-shadow: 10px 10px 13px -3px rgba(0,0,0,0.5);
	transition: opacity .2s ease-in-out;
}

.swatch-modal.show{
	opacity:1;
}

.swatch-modal-header{
	margin-top:5px;
	margin-bottom:5px;
}

.swatch-modal img{
	width:100%;
}

.swatch-modal-apply-button{
	cursor: pointer;
    text-align: center;
    height: 32px;
    text-transform: uppercase;
	border: 1px solid #999999;
	vertical-align: middle;
	font-size: 18px;
	transition: .2s;
}

.swatch-modal-apply-button:hover{
	color:#ffffff;
	background-color:#999999;
} 

.swatch-modal-zone{
	height:25px;
	padding-bottom: 5px;
}

.swatch-modal-zone-checkbox{
	width: 20px;
	height:20px;
	border:1px solid #222222;
	background: none;
	display: inline-block;
	cursor: pointer;
}

.swatch-modal-zone-checkbox.checked{
	background:#222222;
}

.swatch-modal-zone-name{
	font-weight: 400;
	font-size:12px;
	height:30px;
	display: inline-block;
	vertical-align: middle;
	margin-left:5px;
}

input{
	padding:10px !important;
    margin-bottom:10px;
    outline: none;
    border:1px solid #ccc !important;
    box-shadow:none !important;
}

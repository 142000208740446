.slides-container{
    padding:20px;
}

.slides-container img{
    width:100%;
}

.slides {
    display: flex;
    flex-wrap: wrap;
}

.slides > div{
    cursor: pointer;
    padding:10px;
}

.slides img{
    width:125px;
    height:auto;
}

.slides-main{
    width:100%;
    height:auto;
}

.slides-main img{
    margin:0 auto;
    display: block;
}

.slide{
    border: 1px solid transparent;
}

.slide.active{
    border: 1px solid #b20738;
}

@media only screen and (max-width: 600px) {
    .slides img{
        width:100%;
    }
  }


.merchandiser-title{
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
}

.merchandiser-items{
        display:flex;
        flex-wrap: wrap;
  }
  
.merchandiser-item{
    cursor: pointer;
    color: #000000;
    text-align: center;
}
  
ul{
    display:flex;
    flex-wrap: wrap;
}

li{
    list-style-type: none;
}

a{
    text-decoration: none !important;
}
  
.merchandiser-item-title{
    font-weight: 200;
    font-size: 1.5rem;
}

.merchandiser-item-subtitle{
    font-weight:100;
    font-size: 1.0rem;
}
ul{
    list-style-type: none;
    display: block;
}

li{
    display: block;
}

.organization {
    margin: 10px 0px 10px 20px;
    width: 600px;
}

.organization-name {
    font-weight: 500;
    border-bottom: 1px solid #707070;
}

.organization-item {
    color: #707070;
    font-size: 14px;
}

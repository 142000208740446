.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}

header,
footer {
  position: relative;
  padding: 25px;
  background-color: #fff;
}

button:focus {
  outline: 0;
}

header {
  /* border-bottom: 1px solid #eaeaea; */
  color: #999999;
}

footer {
  /* border-top: 1px solid #eaeaea; */
  font-size: 12px;
  line-height: 25px;
}

footer span {
  vertical-align: bottom;
  font-style: italic;
  color: #999999;
}

footer img {
  height: 22px;
  margin-left: 5px;
  margin-top: -15px;
}

.tox-tinymce {
  width: 100%;
}

.tox-statusbar__branding {
  display: none;
}

@media screen and (max-width: 760px) {
  .modal-content {
    top: 500px;
  }
}

.checkbox{
    transition:.2s;
    cursor: pointer;
}
.material-modal{
    position: absolute;
    width:200px;
    height:auto;
    background-color:white;
    z-index: 999;
    box-shadow: 5px 5px 16px -6px rgba(0,0,0,0.75);
    padding:3px;
}

.material-modal img{
    width:100%;
    height: 200px;
}

.material-modal h3{
    font-size:.75rem;
    font-weight: 700;
    text-transform: uppercase;
}

.material-modal h4{
    font-size:.65rem;
    font-weight: 400;
    line-height: 15px;
    padding: 5px;
    display: table-cell;
    vertical-align: middle;
}

.material-modal-body{
    padding:5px;
}

.material-modal-close{
    position: absolute;
    width:25px;
    height:25px;
    right:0;
    top:0;
    background-color:white;
    text-align: center;
    padding-top:3px;
    cursor: pointer;
}

.material-modal-zone{
    display: table;
    height:25px;
}

.material-modal-zone .checkbox{
    font-size: 1.25rem;
}

.material-modal-apply{

}

.material-modal .btn-primary{
   width:100% !important;
}
.pantone-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pantone-group{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pantone-color{
    width:50px;
    height:50px;
    cursor: pointer;
}
.decal-label{
    cursor: pointer;
}
.decal-upload{
    cursor: pointer;
}

.swatch-filters ul {
  display: block;
  text-transform: uppercase;
    -webkit-columns: 2;
            columns: 2;
  }

 .swatch-filters li {
      text-align: left;
      list-style: none;
      line-height: 24px;
      font-size:14px;
      cursor: pointer;
  }

  .swatch-filters.active{
    color:#000;
  }

  .swatch-filter.hidden{
    display:none;
  }

  .swatch-filter.active::before{
    font-family: FontAwesome;
    content: '\F2D3';
    padding-right:4px;
  }

  .swatch-filter.none-active{
    cursor: default;
  }

  .swatch-filter.none-active:hover{
    color:#000;
    cursor: pointer; 
  }

  .swatch-filter.active{
    color:#000;
  }

  .swatch-filter.in-active{
    opacity:.3;
    cursor: default;
  }

  .filter-container{
    padding:10px;
  }

/* Override bootstrap & precompiled CSS */
.pagination-container{
width:100%;
}

.pagination{
    margin:0;
    padding:0;
    justify-content:center;
    align-content: center;
    font-size:14px;
}

.pagination > li > a, .pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: #222222;
	text-decoration: none;
	border: none;
	cursor:pointer;
}

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
	z-index: 2;
	color: #000000;
	font-weight: bold;
	background-color: transparent;
	border:none;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
	z-index: 3;
	color: #000000;
	font-weight: bold;
	cursor: default;
	background-color: transparent;
	border:none;
}

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
	color: #777;
	cursor: not-allowed;
	border:none;
}

.swatch-filters{
	width:100%;
}

.swatches-container{
	padding:10px;
	width:100%;
}

/* INLINE FILTERS */ 

/* 
.swatch-filters ul{
	columns:1;
	padding:0;
}

.swatch-filters li{
	white-space: nowrap;
	text-transform: capitalize;
	border-bottom: 1px solid #eaeaea;
	line-height: 36px;
	font-size: 16px;
	font-weight: 200;
}

.swatches-container .react-sanfona{
	display:flex;
}

.swatches-container .react-sanfona .react-sanfona-item{
	width:33%;
	border: none;
	position: relative;
}

.swatches-container .react-sanfona-item:not(:first-of-type):not(:last-of-type){
	margin-left:5px;
	margin-right:5px;
}

.swatches-container .react-sanfona-item:first-of-type{
	margin-right:10px;
}

.swatches-container .react-sanfona-item:last-of-type{
	margin-left: 10px;
}

.swatches-container .react-sanfona .react-sanfona-item-title:after{
	border-left:1px solid #eaeaea;
}

.swatches-container .react-sanfona h3{
	padding:0;
	padding-left:20px;
	text-align: center;
	vertical-align: middle;
	line-height: 48px;
}

.swatches-container .react-sanfona-item-expanded.filter-expanded .filters{
	border-top:1px solid #eaeaea;
}

.swatches-container .react-sanfona .filters{
	width:fit-content;
	position: absolute;
	min-width:100%;
	z-index: 100000;
}

.swatches-container .react-sanfona .filters.last{
	right:0;
	float:right;
}

.swatches-container .react-sanfona .filters.first{
	left:0;
	float:left;
}

.swatches-container .react-sanfona-item-body-wrapper{
	overflow-y: scroll;
	max-height: 400px;
}

 */
/* END OF INLINE FILTERS */

.swatch-caption-1{

}

.swatch-caption-2{

}

.picker-container {
	width:100%;
}

.swatch-container{
	margin-top:10px;
	display:inline-flex;
	flex-wrap:wrap;
	width:100%;
}

.swatch{
	background-color:transparent;
	padding:2px;
}

.swatch.active img, .swatch.active canvas{
	border: 1px solid #000;
	padding:2px;
}

.swatch.active .swatch-label{
	color:#000;
}

.swatch img{
    width:100%;
}

.swatch canvas{
	width:100%;
	margin-bottom: -6px; /* CSS FIX */
}

.swatch-label{
    font-size:12px;
	line-height:16px;
	overflow:hidden;
	text-overflow: ellipsis;
	
}

.swatch-border{
	position: relative;
	width: 25%;
	background-color:transparent;
	font-size:1.0rem;
	cursor: pointer;
}

.swatch-close{
	margin-top:-10px;
	font-size:24px;
	text-align: center;
	vertical-align: middle;
	background-color: #fff;
	float:right;
	cursor:pointer;
}

.swatch-modal-content{
	background-color: #fff;
}

.swatch-modal-image{
}

.swatch-modal{
	opacity:0;
	position:absolute;
	padding:10px;
	font-size:14px;
	width:200px;
	height:auto;	
	background-color: #fff;
	z-index: 1000;
	box-shadow: 10px 10px 13px -3px rgba(0,0,0,0.5);
	transition: opacity .2s ease-in-out;
}

.swatch-modal.show{
	opacity:1;
}

.swatch-modal-header{
	margin-top:5px;
	margin-bottom:5px;
}

.swatch-modal img{
	width:100%;
}

.swatch-modal-apply-button{
	cursor: pointer;
    text-align: center;
    height: 32px;
    text-transform: uppercase;
	border: 1px solid #999999;
	vertical-align: middle;
	font-size: 18px;
	transition: .2s;
}

.swatch-modal-apply-button:hover{
	color:#ffffff;
	background-color:#999999;
} 

.swatch-modal-zone{
	height:25px;
	padding-bottom: 5px;
}

.swatch-modal-zone-checkbox{
	width: 20px;
	height:20px;
	border:1px solid #222222;
	background: none;
	display: inline-block;
	cursor: pointer;
}

.swatch-modal-zone-checkbox.checked{
	background:#222222;
}

.swatch-modal-zone-name{
	font-weight: 400;
	font-size:12px;
	height:30px;
	display: inline-block;
	vertical-align: middle;
	margin-left:5px;
}

input{
	padding:10px !important;
    margin-bottom:10px;
    outline: none;
    border:1px solid #ccc !important;
    box-shadow:none !important;
}

.file-save{
    width:100%;
}

.file-save input, .file-save textarea{
    font-size:14px;
}

.expander{
    cursor: default;
}

.expander-updating{
    cursor:wait;
}

.expander-nodes{
    background-color:#ffffff;
}

.expander-node {
    line-height: 25px;
    cursor: pointer;
}

.expander-node.active{
    color:#000;
}

.expander-node-picker-container{
    display:flex;
    flex-wrap: wrap;
    padding:10px;
}

.expander-node-picker-container .expander-node{
    width:25%;
}

.expander-node-filters{
    padding:10px;
}

.group-title{
    width:100%;
}

.group-content.icon, .group-content.hybrid {
    display: flex;
    flex-wrap: wrap;
}

.group-content.MATERIAL{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.group-content.NODE > div,
.group-content.ROOT > div{
    flex-basis: 25%;
}

.react-sanfona-item{
    margin-top:10px;
    margin-bottom:10px;
    width:100%;
}

.react-sanfona-item-expanded .expander-nodes{
    max-height: 100% !important;
    transition: max-height 300ms ease-in-out;
}

.react-sanfona-item-title{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding-right:20px;
}

.hexIcon{
    width:100px;
    height:100px;
}

.info.active{
    position: absolute;
    height: auto;
    z-index: 9999;
    background-color:white;
    display:flex;
    box-shadow: 5px 5px 16px 1px rgba(0,0,0,0.25);
    padding:10px;
    margin-left:-10px;
    margin-top:-10px;
}

.info.active > div {
    display:block;
    font-size: .75rem;
    line-height:1.25rem;
    font-weight: 400;
    padding:10px;
}

.info-tag-header{
    color: #424444;
    font-weight:700;
}
.zoom-content{
    cursor: pointer;
    overflow: hidden;
    outline:none;
}

canvas {
    outline: none !important;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
  }

  button:focus {outline:0;}

  a:focus {
    outline: none;
    }
.spinner {
  display: block;
  z-index: 0;
}
.spinner-image {
  cursor: pointer;
}

.spinner-image.loading {
  cursor: wait;
}

.spinner-image.active img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}

.spinner-image img {
  display: none;
}

.spinner-loader {
  width: 100%;
}

.pricer {
}

.pricer-product {
  margin-bottom: 12px;
}

.pricer-item {
  font-size: 16px;
  padding-left: 5px;
}

.pricer-lineitem {
}

.pricer-currency {
}

.pricer-value {
  padding-left: 2px;
}

.pricer-caption {
}

.pricer-sku {
  margin: 0 0 5px 0;
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.pricer-lineitem > div {
  display: inline-block;
}

.sku{
    margin: 0 0 10px 0;
    font-weight: 200;
    font-size: 18px;
}
.leadcreator-icon{
    margin-right:10px;
}
.reset-button{
    position: absolute;
    right: 0;
    top: 0;
    margin: 25px;
    cursor: pointer;
}
/* CONTROLS ----------------------------------------------------------------------------- */

.controls {
  position: relative;
}

.control-bar {
}

.control-icon {
  width: 25px;
  height: 25px;
  padding-right: 10px;
}

.control-option.rotate {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-weight: 200;
  font-style: italic;
  border-bottom: 1px solid #e4e4e4;
}

.removeMarginsAndPadding {
  margin: 0;
  padding: 0;
}

.button {
  cursor: pointer;
}

.control-options-container {
  display: flex;
  justify-content: center;
}

.control-option {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  padding: 0;
  flex-grow: 1;
  flex-basis: 0;
}

/* GLOBAL */
.share-container,
.download-container,
.mode-container {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 27px 44px -2px rgba(0, 0, 0, 0.14);
  z-index: 1000;
}

/* SHARING */
.share-container > div {
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
}

.share-container > div:last-child {
  border-bottom: none;
}

.share-active {
  color: #ee3b33;
}

/* DOWNLOADING */
.download-container > div {
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
}

.download-container > div:last-child {
  border-bottom: none;
}

.download-active {
  color: #ee3b33;
}

.download-container {
  position: relative;
}

/* SHARE */
.share-container > div {
  text-align: center;
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
}

.share-container > span {
  text-align: center;
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
}

.share-container a {
  display: block;
}

.share-container > div:last-child {
  border-bottom: none;
}

.share-active {
  color: #ee3b33;
}

.share-container {
  z-index: 50;
}

/* ZOOM */
.zoom-active {
  color: #ee3b33;
}

/* MODE */
.mode-container > div {
  display: flex;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}

.mode-container > div span {
  padding-left: 5px;
  font-size: 14px;
  letter-spacing: 0;
  width: 100%;
  text-transform: capitalize;
}

.mode-container > div:last-child {
  border-bottom: none;
}

.mode-active {
  color: #ee3b33;
}

@media only screen and (max-width: 960px) {
  .download-container,
  .share-container {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 9999;
  }

  .control-text {
    display: none;
  }
}

/* override react-notifications package css */
.notification {
  margin-top: 500px !important;
}



#tricon {
    background-color: black;
}

.tri {
    height: 100px;
    width: 100px;
    margin:0 auto;
    display: block;
    margin-top:-5px;
}

.trd {
    height: 250px;
    width: 250px;
    background-color:black;
}

#tri1 {
    /*background-image: url("https://machinecore.thebigpicturemachine.com/Ziing3Server/Ziing3.aspx?req=render&hon-ceres-HCW1_0030&fmt=jpg&bgc=000000&size=750&layer=root_stat$lt=SO$zo=2&layer=root-uphl1_up00$lt=RO$zo=3$mat=haml17&layer=root-bases-pa_drop$lt=SO$zo=7&layer=root-bases-pa_stat$lt=SO$zo=8&layer=root-bases-pa-backs-dflt_stat$lt=SO$zo=9&layer=root-bases-pa-backs-dflt-mesh1_me00$lt=RO$zo=10$hex=192a43&layer=root-bases-pa-backs-dflt-arms-aplat_stat$lt=SO$zo=13");*/
    background-color: black;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
            clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    position: absolute;
}

#tri2 {
    /*background-image: url("https://machinecore.thebigpicturemachine.com/Ziing3Server/Ziing3.aspx?req=render&hon-ceres-HCW1_0030&fmt=jpg&bgc=#000000&size=750&layer=root_stat$lt=SO$zo=2&layer=root-uphl1_up00$lt=RO$zo=3$mat=haml17&layer=root-bases-pa_drop$lt=SO$zo=7&layer=root-bases-pa_stat$lt=SO$zo=8&layer=root-bases-pa-backs-dflt_stat$lt=SO$zo=9&layer=root-bases-pa-backs-dflt-mesh1_me00$lt=RO$zo=10$hex=192a43&layer=root-bases-pa-backs-dflt-arms-aplat_stat$lt=SO$zo=13");*/
    background-color: black;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
            clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    position: absolute;
    transform: rotate(90deg);
}

#tri3 {
    /*background-image: url("https://machinecore.thebigpicturemachine.com/Ziing3Server/Ziing3.aspx?req=render&hon-ceres-HCW1_0030&fmt=jpg&bgc=#000000&size=750&layer=root_stat$lt=SO$zo=2&layer=root-uphl1_up00$lt=RO$zo=3$mat=haml17&layer=root-bases-pa_drop$lt=SO$zo=7&layer=root-bases-pa_stat$lt=SO$zo=8&layer=root-bases-pa-backs-dflt_stat$lt=SO$zo=9&layer=root-bases-pa-backs-dflt-mesh1_me00$lt=RO$zo=10$hex=192a43&layer=root-bases-pa-backs-dflt-arms-aplat_stat$lt=SO$zo=13");*/
    background-color: black;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
            clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    position: absolute;
    transform: rotate(180deg);
}

#tri4 {
    /*background-image: url("https://machinecore.thebigpicturemachine.com/Ziing3Server/Ziing3.aspx?req=render&hon-ceres-HCW1_0030&bgc=#000000&fmt=jpg&size=750&layer=root_stat$lt=SO$zo=2&layer=root-uphl1_up00$lt=RO$zo=3$mat=haml17&layer=root-bases-pa_drop$lt=SO$zo=7&layer=root-bases-pa_stat$lt=SO$zo=8&layer=root-bases-pa-backs-dflt_stat$lt=SO$zo=9&layer=root-bases-pa-backs-dflt-mesh1_me00$lt=RO$zo=10$hex=192a43&layer=root-bases-pa-backs-dflt-arms-aplat_stat$lt=SO$zo=13");*/
    background-color: black;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
            clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    position: absolute;
    transform: rotate(270deg);
}

.ui-title h3{
    font-weight: 400;
    font-size:14px;
    text-transform: uppercase;
}

.swipe-box{
    width:100%;
    padding:10px;
    font-size:18px;
    text-align: center;
    margin:0 auto;
    border:1px solid #555555;
    margin-bottom:20px;
    border-radius:10px;
    background-color:#555555;
    color:#ffffff;
}

.swipe-box > span {
    margin:0 auto;
}
.slides-container{
    padding:20px;
}

.slides-container img{
    width:100%;
}

.slides {
    display: flex;
    flex-wrap: wrap;
}

.slides > div{
    cursor: pointer;
    padding:10px;
}

.slides img{
    width:125px;
    height:auto;
}

.slides-main{
    width:100%;
    height:auto;
}

.slides-main img{
    margin:0 auto;
    display: block;
}

.slide{
    border: 1px solid transparent;
}

.slide.active{
    border: 1px solid #b20738;
}

@media only screen and (max-width: 600px) {
    .slides img{
        width:100%;
    }
  }

.information {
  text-align: left;
}

.information-title {
  font-size: 32px;
  margin-bottom: 0;
}

.information-subtitle {
  font-size: 24px;
  margin-top: 10px;
  font-weight: 200px;
}

.information-lineItem {
}

.information-lineItem-parentTitle {
  text-transform: capitalize;
  font-weight: 800;
  font-size: 14px;
}

.information-lineItem-title {
  text-transform: capitalize;
  margin-left: 10px;
  font-size: 14px;
}

@media only screen and (max-width: 992px) {
  /*hide text and remove icon padding*/
  .information-title {
    font-size: 20px;
    font-weight: 200;
  }

  .information-subtitle {
    font-size: 16px;
  }
}

.disclaimer {
    margin-top:25px;
}
.model-viewer {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.ar-qrcode {
  background: white;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.qrcode-header {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  letter-spacing: 2px;
}

.qrcode-code svg {
  height: 400px;
  width: 400px;
  text-align: center;
  margin-bottom: 20px;
}

.qrcode-code canvas {
  margin-bottom: 20px;
}

.qrcode-text {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.ar-container {
  visibility: hidden;
  height: 0;
}

.ar-img {
  visibility: hidden;
  height: 0;
}

.three-d-button {
  margin: 15px 10px 0px 0px;
}

.view-in-room-button:hover {
  cursor: pointer;
}

.quicklook-icon {
  height: 15px;
  width: 15px;
}

.sceneviewer-icon {
  height: 20px;
  width: 20px;
}

.view-in-room-button {
  margin: 10px 0 0 0;
  padding: 5px 7px 5px 7px;
  font-weight: bold;

  /* width: 200px;
  padding: 5px 7px 5px 7px;
  border: 1px solid #bdbdbd; */
}

.viewinroom {
  margin: 10px 0 0 0;
  padding: 5px 7px 5px 7px;
  font-weight: bold;
}

.ar-device-link {
  color: #000;
  text-decoration: underline !important;
}
.ar-device-link:hover {
  color: #000;
  text-decoration: underline;
}

/*hide click me link*/
.ar-link {
  visibility: hidden;
}

.model-viewer-container {
  display: block;
  margin: 0 0 100px 0;
}

/* DESKTOP VIEW */
/* #spinner,
.view-in-room-button {
  border: 2px solid #bdbdbd; 
  position: relative;

  left: 100%;
  transform: translateX(-100%);
  bottom: 85px;
  text-align: center;
  cursor: pointer;
  width: 43%;

  line-height: 30px;
} 

#spinner, 
.viewinroom {
  border: 2px solid #bdbdbd; 
  position: relative;

  left: 100%;
  transform: translateX(-100%);
  bottom: 85px;
  text-align: center;
  cursor: pointer;
  width: 43%;

  line-height: 30px;
}*/

/* MOBILE VIEW */
/* @media only screen and (max-width: 767px){

  #spinner,
.view-in-room-button {

  border: 2px solid #bdbdbd;
  position: relative;

  left: 50%;
  transform: translateX(-50%);
  bottom: 90px;
  text-align: center;
  cursor: pointer;
  width: 40%;

  cursor: pointer;

  font-size: 10px;
  line-height: 30px;
}

}

@media only screen and (max-width: 767px){

  #spinner, 
  .viewinroom {

  border: 2px solid #bdbdbd; 

  position: relative;

  left: 50%;
  transform: translateX(-50%);
  bottom: 90px;
  text-align: center;
  cursor: pointer;
  width: 40%;

  cursor: pointer;

  font-size: 10px;
  line-height: 30px;

  } 
} */


.slab-1{
    position: relative;
}

.slab-1.w-6{
    width: 100%;
}

.slab-1.w-7{
    width:100%;
}

.slab-1.w-8{
    width: 100%;
}

.slab-2{
    position: relative;
}

.scrollable{
  height: 100vh;
  overflow-y: auto;
  min-height: 100%;
}

@media only screen and (max-width: 768px){

    .slab-1{
        position: relative;
        width: 100% !important;
    }

    .slab-2{

    }
}

.modal{
  position: absolute;
}

.processing-overlay{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #000;
    z-index: 999998;
    opacity: .25;
    transition:.25s ease-in-out;
  }
  
  .processing-status svg{
   margin:0 auto;
   display: block;
   margin-top:100px;
  }
  
  .processing-status{
    position: fixed;
    color:#333333;
    border-radius: 25px;
    z-index: 999999;
    font-size:24px;
    opacity:0;
  }

  .processing-status.show{
    opacity: 1;
  }

  @keyframes pulse_animation {
    0% { transform: scale(1); }
    30% { transform: scale(1); }
    40% { transform: scale(1.08); }
    50% { transform: scale(1); }
    60% { transform: scale(1); }
    70% { transform: scale(1.05); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
}

.pulse {
    /*
    animation-name: pulse_animation;
    animation-duration: 5000ms;
    transform-origin:70% 70%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    */
}

.groupings {
  display:block;
}
ul{
    list-style-type: none;
    display: block;
}

li{
    display: block;
}

.organization {
    margin: 10px 0px 10px 20px;
    width: 600px;
}

.organization-name {
    font-weight: 500;
    border-bottom: 1px solid #707070;
}

.organization-item {
    color: #707070;
    font-size: 14px;
}


.merchandiser-title{
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
}

.merchandiser-items{
        display:flex;
        flex-wrap: wrap;
  }
  
.merchandiser-item{
    cursor: pointer;
    color: #000000;
    text-align: center;
}
  
ul{
    display:flex;
    flex-wrap: wrap;
}

li{
    list-style-type: none;
}

a{
    text-decoration: none !important;
}
  
.merchandiser-item-title{
    font-weight: 200;
    font-size: 1.5rem;
}

.merchandiser-item-subtitle{
    font-weight:100;
    font-size: 1.0rem;
}


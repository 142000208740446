.information {
  text-align: left;
}

.information-title {
  font-size: 32px;
  margin-bottom: 0;
}

.information-subtitle {
  font-size: 24px;
  margin-top: 10px;
  font-weight: 200px;
}

.information-lineItem {
}

.information-lineItem-parentTitle {
  text-transform: capitalize;
  font-weight: 800;
  font-size: 14px;
}

.information-lineItem-title {
  text-transform: capitalize;
  margin-left: 10px;
  font-size: 14px;
}

@media only screen and (max-width: 992px) {
  /*hide text and remove icon padding*/
  .information-title {
    font-size: 20px;
    font-weight: 200;
  }

  .information-subtitle {
    font-size: 16px;
  }
}


.expander{
    cursor: default;
}

.expander-updating{
    cursor:wait;
}

.expander-nodes{
    background-color:#ffffff;
}

.expander-node {
    line-height: 25px;
    cursor: pointer;
}

.expander-node.active{
    color:#000;
}

.expander-node-picker-container{
    display:flex;
    flex-wrap: wrap;
    padding:10px;
}

.expander-node-picker-container .expander-node{
    width:25%;
}

.expander-node-filters{
    padding:10px;
}

.group-title{
    width:100%;
}

.group-content.icon, .group-content.hybrid {
    display: flex;
    flex-wrap: wrap;
}

.group-content.MATERIAL{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.group-content.NODE > div,
.group-content.ROOT > div{
    flex-basis: 25%;
}

.react-sanfona-item{
    margin-top:10px;
    margin-bottom:10px;
    width:100%;
}

.react-sanfona-item-expanded .expander-nodes{
    max-height: 100% !important;
    transition: max-height 300ms ease-in-out;
}

.react-sanfona-item-title{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding-right:20px;
}

.hexIcon{
    width:100px;
    height:100px;
}

.info.active{
    position: absolute;
    height: auto;
    z-index: 9999;
    background-color:white;
    display:flex;
    -webkit-box-shadow: 5px 5px 16px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 5px 5px 16px 1px rgba(0,0,0,0.25);
    box-shadow: 5px 5px 16px 1px rgba(0,0,0,0.25);
    padding:10px;
    margin-left:-10px;
    margin-top:-10px;
}

.info.active > div {
    display:block;
    font-size: .75rem;
    line-height:1.25rem;
    font-weight: 400;
    padding:10px;
}

.info-tag-header{
    color: #424444;
    font-weight:700;
}
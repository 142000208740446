.material-modal{
    position: absolute;
    width:200px;
    height:auto;
    background-color:white;
    z-index: 999;
    -webkit-box-shadow: 5px 5px 16px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 16px -6px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 16px -6px rgba(0,0,0,0.75);
    padding:3px;
}

.material-modal img{
    width:100%;
    height: 200px;
}

.material-modal h3{
    font-size:.75rem;
    font-weight: 700;
    text-transform: uppercase;
}

.material-modal h4{
    font-size:.65rem;
    font-weight: 400;
    line-height: 15px;
    padding: 5px;
    display: table-cell;
    vertical-align: middle;
}

.material-modal-body{
    padding:5px;
}

.material-modal-close{
    position: absolute;
    width:25px;
    height:25px;
    right:0;
    top:0;
    background-color:white;
    text-align: center;
    padding-top:3px;
    cursor: pointer;
}

.material-modal-zone{
    display: table;
    height:25px;
}

.material-modal-zone .checkbox{
    font-size: 1.25rem;
}

.material-modal-apply{

}

.material-modal .btn-primary{
   width:100% !important;
}
.pantone-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pantone-group{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pantone-color{
    width:50px;
    height:50px;
    cursor: pointer;
}
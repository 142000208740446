/* CONTROLS ----------------------------------------------------------------------------- */

.controls {
  position: relative;
}

.control-bar {
}

.control-icon {
  width: 25px;
  height: 25px;
  padding-right: 10px;
}

.control-option.rotate {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-weight: 200;
  font-style: italic;
  border-bottom: 1px solid #e4e4e4;
}

.removeMarginsAndPadding {
  margin: 0;
  padding: 0;
}

.button {
  cursor: pointer;
}

.control-options-container {
  display: flex;
  justify-content: center;
}

.control-option {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  padding: 0;
  flex-grow: 1;
  flex-basis: 0;
}

/* GLOBAL */
.share-container,
.download-container,
.mode-container {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 0px 27px 44px -2px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 27px 44px -2px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 27px 44px -2px rgba(0, 0, 0, 0.14);
  z-index: 1000;
}

/* SHARING */
.share-container > div {
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
}

.share-container > div:last-child {
  border-bottom: none;
}

.share-active {
  color: #ee3b33;
}

/* DOWNLOADING */
.download-container > div {
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
}

.download-container > div:last-child {
  border-bottom: none;
}

.download-active {
  color: #ee3b33;
}

.download-container {
  position: relative;
}

/* SHARE */
.share-container > div {
  text-align: center;
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
}

.share-container > span {
  text-align: center;
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
}

.share-container a {
  display: block;
}

.share-container > div:last-child {
  border-bottom: none;
}

.share-active {
  color: #ee3b33;
}

.share-container {
  z-index: 50;
}

/* ZOOM */
.zoom-active {
  color: #ee3b33;
}

/* MODE */
.mode-container > div {
  display: flex;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}

.mode-container > div span {
  padding-left: 5px;
  font-size: 14px;
  letter-spacing: 0;
  width: 100%;
  text-transform: capitalize;
}

.mode-container > div:last-child {
  border-bottom: none;
}

.mode-active {
  color: #ee3b33;
}

@media only screen and (max-width: 960px) {
  .download-container,
  .share-container {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 9999;
  }

  .control-text {
    display: none;
  }
}

/* override react-notifications package css */
.notification {
  margin-top: 500px !important;
}

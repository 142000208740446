
.slab-1{
    position: relative;
}

.slab-1.w-6{
    width: 100%;
}

.slab-1.w-7{
    width:100%;
}

.slab-1.w-8{
    width: 100%;
}

.slab-2{
    position: relative;
}

.scrollable{
  height: 100vh;
  overflow-y: auto;
  min-height: 100%;
}

@media only screen and (max-width: 768px){

    .slab-1{
        position: relative;
        width: 100% !important;
    }

    .slab-2{

    }
}


#tricon {
    background-color: black;
}

.tri {
    height: 100px;
    width: 100px;
    margin:0 auto;
    display: block;
    margin-top:-5px;
}

.trd {
    height: 250px;
    width: 250px;
    background-color:black;
}

#tri1 {
    /*background-image: url("https://machinecore.thebigpicturemachine.com/Ziing3Server/Ziing3.aspx?req=render&hon-ceres-HCW1_0030&fmt=jpg&bgc=000000&size=750&layer=root_stat$lt=SO$zo=2&layer=root-uphl1_up00$lt=RO$zo=3$mat=haml17&layer=root-bases-pa_drop$lt=SO$zo=7&layer=root-bases-pa_stat$lt=SO$zo=8&layer=root-bases-pa-backs-dflt_stat$lt=SO$zo=9&layer=root-bases-pa-backs-dflt-mesh1_me00$lt=RO$zo=10$hex=192a43&layer=root-bases-pa-backs-dflt-arms-aplat_stat$lt=SO$zo=13");*/
    background-color: black;
    clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    position: absolute;
}

#tri2 {
    /*background-image: url("https://machinecore.thebigpicturemachine.com/Ziing3Server/Ziing3.aspx?req=render&hon-ceres-HCW1_0030&fmt=jpg&bgc=#000000&size=750&layer=root_stat$lt=SO$zo=2&layer=root-uphl1_up00$lt=RO$zo=3$mat=haml17&layer=root-bases-pa_drop$lt=SO$zo=7&layer=root-bases-pa_stat$lt=SO$zo=8&layer=root-bases-pa-backs-dflt_stat$lt=SO$zo=9&layer=root-bases-pa-backs-dflt-mesh1_me00$lt=RO$zo=10$hex=192a43&layer=root-bases-pa-backs-dflt-arms-aplat_stat$lt=SO$zo=13");*/
    background-color: black;
    clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    position: absolute;
    transform: rotate(90deg);
}

#tri3 {
    /*background-image: url("https://machinecore.thebigpicturemachine.com/Ziing3Server/Ziing3.aspx?req=render&hon-ceres-HCW1_0030&fmt=jpg&bgc=#000000&size=750&layer=root_stat$lt=SO$zo=2&layer=root-uphl1_up00$lt=RO$zo=3$mat=haml17&layer=root-bases-pa_drop$lt=SO$zo=7&layer=root-bases-pa_stat$lt=SO$zo=8&layer=root-bases-pa-backs-dflt_stat$lt=SO$zo=9&layer=root-bases-pa-backs-dflt-mesh1_me00$lt=RO$zo=10$hex=192a43&layer=root-bases-pa-backs-dflt-arms-aplat_stat$lt=SO$zo=13");*/
    background-color: black;
    clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    position: absolute;
    transform: rotate(180deg);
}

#tri4 {
    /*background-image: url("https://machinecore.thebigpicturemachine.com/Ziing3Server/Ziing3.aspx?req=render&hon-ceres-HCW1_0030&bgc=#000000&fmt=jpg&size=750&layer=root_stat$lt=SO$zo=2&layer=root-uphl1_up00$lt=RO$zo=3$mat=haml17&layer=root-bases-pa_drop$lt=SO$zo=7&layer=root-bases-pa_stat$lt=SO$zo=8&layer=root-bases-pa-backs-dflt_stat$lt=SO$zo=9&layer=root-bases-pa-backs-dflt-mesh1_me00$lt=RO$zo=10$hex=192a43&layer=root-bases-pa-backs-dflt-arms-aplat_stat$lt=SO$zo=13");*/
    background-color: black;
    clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    position: absolute;
    transform: rotate(270deg);
}

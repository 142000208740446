.model-viewer {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.ar-qrcode {
  background: white;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.qrcode-header {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  letter-spacing: 2px;
}

.qrcode-code svg {
  height: 400px;
  width: 400px;
  text-align: center;
  margin-bottom: 20px;
}

.qrcode-code canvas {
  margin-bottom: 20px;
}

.qrcode-text {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.ar-container {
  visibility: hidden;
  height: 0;
}

.ar-img {
  visibility: hidden;
  height: 0;
}

.three-d-button {
  margin: 15px 10px 0px 0px;
}

.view-in-room-button:hover {
  cursor: pointer;
}

.quicklook-icon {
  height: 15px;
  width: 15px;
}

.sceneviewer-icon {
  height: 20px;
  width: 20px;
}

.view-in-room-button {
  margin: 10px 0 0 0;
  padding: 5px 7px 5px 7px;
  font-weight: bold;

  /* width: 200px;
  padding: 5px 7px 5px 7px;
  border: 1px solid #bdbdbd; */
}

.viewinroom {
  margin: 10px 0 0 0;
  padding: 5px 7px 5px 7px;
  font-weight: bold;
}

.ar-device-link {
  color: #000;
  text-decoration: underline !important;
}
.ar-device-link:hover {
  color: #000;
  text-decoration: underline;
}

/*hide click me link*/
.ar-link {
  visibility: hidden;
}

.model-viewer-container {
  display: block;
  margin: 0 0 100px 0;
}

/* DESKTOP VIEW */
/* #spinner,
.view-in-room-button {
  border: 2px solid #bdbdbd; 
  position: relative;

  left: 100%;
  transform: translateX(-100%);
  bottom: 85px;
  text-align: center;
  cursor: pointer;
  width: 43%;

  line-height: 30px;
} 

#spinner, 
.viewinroom {
  border: 2px solid #bdbdbd; 
  position: relative;

  left: 100%;
  transform: translateX(-100%);
  bottom: 85px;
  text-align: center;
  cursor: pointer;
  width: 43%;

  line-height: 30px;
}*/

/* MOBILE VIEW */
/* @media only screen and (max-width: 767px){

  #spinner,
.view-in-room-button {

  border: 2px solid #bdbdbd;
  position: relative;

  left: 50%;
  transform: translateX(-50%);
  bottom: 90px;
  text-align: center;
  cursor: pointer;
  width: 40%;

  cursor: pointer;

  font-size: 10px;
  line-height: 30px;
}

}

@media only screen and (max-width: 767px){

  #spinner, 
  .viewinroom {

  border: 2px solid #bdbdbd; 

  position: relative;

  left: 50%;
  transform: translateX(-50%);
  bottom: 90px;
  text-align: center;
  cursor: pointer;
  width: 40%;

  cursor: pointer;

  font-size: 10px;
  line-height: 30px;

  } 
} */

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}

header,
footer {
  position: relative;
  padding: 25px;
  background-color: #fff;
}

button:focus {
  outline: 0;
}

header {
  /* border-bottom: 1px solid #eaeaea; */
  color: #999999;
}

footer {
  /* border-top: 1px solid #eaeaea; */
  font-size: 12px;
  line-height: 25px;
}

footer span {
  vertical-align: bottom;
  font-style: italic;
  color: #999999;
}

footer img {
  height: 22px;
  margin-left: 5px;
  margin-top: -15px;
}

.tox-tinymce {
  width: 100%;
}

.tox-statusbar__branding {
  display: none;
}

@media screen and (max-width: 760px) {
  .modal-content {
    top: 500px;
  }
}

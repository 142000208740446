.pricer {
}

.pricer-product {
  margin-bottom: 12px;
}

.pricer-item {
  font-size: 16px;
  padding-left: 5px;
}

.pricer-lineitem {
}

.pricer-currency {
}

.pricer-value {
  padding-left: 2px;
}

.pricer-caption {
}

.pricer-sku {
  margin: 0 0 5px 0;
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.pricer-lineitem > div {
  display: inline-block;
}

.spinner {
  display: block;
  z-index: 0;
}
.spinner-image {
  cursor: pointer;
}

.spinner-image.loading {
  cursor: wait;
}

.spinner-image.active img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}

.spinner-image img {
  display: none;
}

.spinner-loader {
  width: 100%;
}

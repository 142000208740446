.ui-title h3{
    font-weight: 400;
    font-size:14px;
    text-transform: uppercase;
}

.swipe-box{
    width:100%;
    padding:10px;
    font-size:18px;
    text-align: center;
    margin:0 auto;
    border:1px solid #555555;
    margin-bottom:20px;
    border-radius:10px;
    background-color:#555555;
    color:#ffffff;
}

.swipe-box > span {
    margin:0 auto;
}
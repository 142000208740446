
.modal{
  position: absolute;
}

.processing-overlay{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #000;
    z-index: 999998;
    opacity: .25;
    transition:.25s ease-in-out;
  }
  
  .processing-status svg{
   margin:0 auto;
   display: block;
   margin-top:100px;
  }
  
  .processing-status{
    position: fixed;
    color:#333333;
    border-radius: 25px;
    z-index: 999999;
    font-size:24px;
    opacity:0;
  }

  .processing-status.show{
    opacity: 1;
  }

  @keyframes pulse_animation {
    0% { transform: scale(1); }
    30% { transform: scale(1); }
    40% { transform: scale(1.08); }
    50% { transform: scale(1); }
    60% { transform: scale(1); }
    70% { transform: scale(1.05); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
}

.pulse {
    /*
    animation-name: pulse_animation;
    animation-duration: 5000ms;
    transform-origin:70% 70%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    */
}

.groupings {
  display:block;
}